<template>
  <v-data-iterator
      item-key="uuid"
      class="layout fill-height column justify-start"
      :items="items"
      :loading="loading"
      :server-items-length="itemsLength"
      :items-per-page="itemsPerPage"
      :hide-default-footer="true"
      :options.sync="options"
  >
    <template v-slot:loading>
      <v-layout fill-height column justify-center align-center class="mt-auto">
        <v-progress-linear indeterminate :active="!!loading" value="true"></v-progress-linear>
      </v-layout>
    </template>
    <template v-slot:no-data>
      <div class="text-center pa-8">No data available</div>
    </template>
    <template v-slot:default="{ items, isExpanded, expand }">
      <slot v-bind="{ items, isExpanded, expand }"></slot>
    </template>
    <template v-slot:footer="{options, pagination}">
      <div class="d-flex py-4">
        <v-spacer></v-spacer>
        <v-data-footer
            v-bind="{
              options: options,
              pagination: pagination,
              disableItemsPerPage: true,
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [-1]
            }">
          <template v-slot:prepend>
            <v-spacer></v-spacer>
          </template>
        </v-data-footer>
      </div>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    loading: {
      type: [Boolean, String],
      default: false,
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    pagination: {
      default: () => ({
        page: 1,
        itemsPerPage: -1,
        pageStart: 1,
        pageStop: 1,
        pageCount: 1,
        itemsLength: 0
      })
    },
  },
  computed: {
    itemsPerPage() {
      return this.pagination.itemsPerPage;
    },
    itemsLength() {
      return this.pagination.itemsLength;
    }
  },
  data: () => ({
    options: {},
  }),
  watch: {
    options: {
      handler({page, itemsPerPage}) {
        this.$emit('update', {
          page: page,
          limit: itemsPerPage
        })
      },
      deep: true,
    },
  },
}
</script>
