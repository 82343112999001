var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-iterator',{staticClass:"layout fill-height column justify-start",attrs:{"item-key":"uuid","items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.itemsLength,"items-per-page":_vm.itemsPerPage,"hide-default-footer":true,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-layout',{staticClass:"mt-auto",attrs:{"fill-height":"","column":"","justify-center":"","align-center":""}},[_c('v-progress-linear',{attrs:{"indeterminate":"","active":!!_vm.loading,"value":"true"}})],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-8"},[_vm._v("No data available")])]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_vm._t("default",null,null,{ items: items, isExpanded: isExpanded, expand: expand })]}},{key:"footer",fn:function(ref){
var options = ref.options;
var pagination = ref.pagination;
return [_c('div',{staticClass:"d-flex py-4"},[_c('v-spacer'),_c('v-data-footer',_vm._b({scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-spacer')]},proxy:true}],null,true)},'v-data-footer',{
            options: options,
            pagination: pagination,
            disableItemsPerPage: true,
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [-1]
          },false))],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }